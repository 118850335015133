import { TECH } from "content/tech-stack";
import { Link } from "components/link";

export const CV = {
  name: "Darshan Parbadiya",
  title: "Fullstack Engineer",
  primary_stack: [TECH.typescript, TECH.react, TECH.nodejs, TECH.tailwindcss, TECH.mysql],
  email: "parbadid@mcmaster.ca",
  mobile: {
    href: `tel:+4376777727`,
    number: "+1(437)-677-7727",
  },

  experience: [
    
    {
      dateFrom: "Jan 2025",
      dateTo: "Present",
      city: "Ontario",
      country: "Canada",
      company: "Government of Ontario - Ministry of Transportation ",
      title: "Jr. Technical Analyst, Co-op",
      type: ["business analysis", "software development", "data analysis", "relevant"],
      description: "",
      responsibilities: [
        {
          content:
            "Contributed to the design and development of innovative, enterprise-grade web applications using Angular and a wide range of backend technologies.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Developed comprehensive unit tests for Spring projects using Mockito and for Angular applications with Jasmine, while automating repetitive tasks through custom scripts to enhance development efficiency.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
      ],
    },
    {
      dateFrom: "May 2024",
      dateTo: "Dec 2024",
      city: "Ontario",
      country: "Canada",
      company: "Portland Investment Counsel",
      title: "Business Systems Analyst, Co-op",
      type: ["business analysis", "software development", "data analysis", "relevant"],
      description: "",
      responsibilities: [
        {
          content:
            "Designed and developed user-centric applications in Java, leveraging ReactJS and various backend technologies to deliver scalable, enterprise-grade solutions while consistently meeting project requirements and deadlines.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Built robust automated tests with Jest and Selenium, enhancing application stability and reducing production defects. ",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        // {
        //   content:
        //     "Employed test-driven development (TDD) practices to build reliable, bug-free solutions, and maintained automated test suites for consistent regression testing.",
        //   type: ["software development", "quality assurance", "relevant"],
        // },
      ],
    },
    {
      dateFrom: "Dec 2023",
      dateTo: "May 2024",
      city: "Ontario",
      country: "Canada",
      company: "Direct Cell",
      title: "Technical Support Specialist, Part Time",
      description: "",
      type: ["technical support", "hardware/software repair", "customer service", "relevant"],
      responsibilities: [
        {
          content:
            "Diagnosed and repaired a wide range of electronic devices, including phones, tablets, laptops, and gaming consoles, addressing both hardware and software issues.",
          type: ["hardware repair", "software troubleshooting", "relevant"],
        },
        {
          content: "Provided technical support by troubleshooting and resolving problems, ensuring efficient performance and high customer satisfaction.",
          type: ["customer service", "client relations", "relevant"],
        },
      ],
    },
    {
      dateFrom: "Mar 2022",
      dateTo: "Aug 2023",
      city: "Surat",
      country: "India",
      company: "Epsilon Tech",
      title: "Application Developer",
      description: "",
      type: ["software development", "front-end development", "web / tech dev", "relevant"],
      responsibilities: [
        {
          content:
            "Crafted dynamic, full-stack web applications with a robust Node.js backend, seamlessly paired with an intuitive, visually striking ReactJS frontend for an exceptional user experience. .",
          type: ["web development", "user experience", "relevant"],
        },
        {
          content:
            "Created and maintained comprehensive documentation for code, processes, and applications, contributing to the clarity and transparency of project workflows.",
          type: ["software development", "user experience", "relevant"],
        },
      ],
    },
  ],
  capabilities: {
    languages: [
      {
        name: "English - bilingual",
        Icon: null,
      },
      {
        name: "Gujarati - native",
        Icon: null,
      },
    ],
    programmingLanguages: [
      TECH.typescript,
      TECH.javascript,
      TECH.html_5,
      TECH.css_3,
      TECH.sass,
      TECH.graphql,
      TECH.markdown,
      TECH.liquid,
      TECH.sql,
      TECH.ruby,
      TECH.php,
    ],
    librariesFrameworks: [
      TECH.react,
      TECH.tailwindcss,
      TECH.nextjs,
      TECH.trpc,
      TECH.prisma,
      TECH.react_query,
      TECH.remix,
      TECH.jest,
      TECH.turborepo,
      TECH.nodejs,
      TECH.express,
      TECH.webpack,
      TECH.framer,
      TECH.redis,
    ],
    serviceProviders: [
      TECH.vercel,
      TECH.aws,
      TECH.firebase,
      TECH.netlify,
      TECH.planetscale,
      TECH.github,
    ],
    marketing: [TECH.vercel, TECH.aws, TECH.firebase, TECH.netlify, TECH.planetscale, TECH.github],
    dataProviders: [TECH.shopify, TECH.vend, TECH.erply, TECH.dear, TECH.micros, TECH.wordpress],
    tools: [
      TECH.intellij_idea,
      TECH.git,
      TECH.yarn,
      TECH.npm,
      TECH.illustrator,
      TECH.photoshop,
      TECH.figma,
      TECH.postman,
      TECH.ftp,
      TECH.office,
      TECH.dev_tools,
    ],
  },
  education: [
    {
      dateFrom: "Sept 2023",
      dateTo: "Sept 2025(Expected)",
      city: "Hamilton",
      country: "Canada",
      company: "McMaster University",
      title: "M.Eng, Systems And Technology (Co-op Program)",
      type: ["education", "relevant", "deep learning", "AI/ML"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Deep Learning, Artificial Intelligence (AI), Machine Learning (ML), Multivariate statistics, Cloud computing,  Big data analytics.",
          type: ["software development", "relevant"],
        },
      ],
    },
    {
      dateFrom: "June 2017",
      dateTo: "May 2021",
      city: "India",
      country: "India",
      company: "Gujarat Technological University",
      title: "B.Tech, Computer Engineering; CGPA: 9.12/10",
      type: ["education", "relevant", "computer engineering"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Compiler design, DBMS, Theory of Computation, Computer Networks, Java, C, C++, Python.",
          type: ["software development", "relevant"],
        },
      ],
    },
  ],
};